<template>
  <tr class="product-line">
    <td class="product-name left">{{ item.name }}</td>
    <td class="left">{{ item.sku }}</td>
    <template>
      <template v-if="!editPrice">
        <td class="item-clickable left" @click="openPriceEdit">
          <span class="font-weight-medium">{{ Math.abs(item.price).toFixed(2) }}</span>
        </td>
      </template>
      <template v-else>
        <td>
          <v-text-field v-model="newPrice" class="column-edit" @click:append="editPrice = false" dense :loading="priceLoading"
                        @click:append-outer="updatePrice" @keyup.enter="updatePrice"
                        append-icon="mdi-arrow-u-left-bottom" append-outer-icon="mdi-check"
          />
        </td>
      </template>
    </template>
    <template>
      <td class="status-product">
        <v-switch v-model="status" :loading="statusLoading" @change="updateStatus" class="status-switch" color="orange" inset flat hide-details />
      </td>
    </template>
    <template v-if="showSort">
      <template v-if="!editPosition">
        <td class="item-clickable left" @click="editPosition = true">
          <span class="font-weight-medium">{{ position }}</span>
        </td>
      </template>
      <template v-else>
        <td class="left-filed">
          <v-text-field v-model="position" class="column-edit" @click:append="editPosition = false" :autofocus="editPosition" dense :loading="sortLoading"
                        append-outer-icon="mdi-check" @click:append-outer="updateCategoryPosition" @keyup.enter="updateCategoryPosition"
          />
        </td>
      </template>
    </template>
    <td>
      <div class="desktop-button left">
        <v-btn small depressed class="blue-grey lighten-5 blue-grey--text text--darken-1 mr-3" @click="openProductEditModal">
          {{ $t('Edit') }}</v-btn>
      </div>
      <div class="mobile-button left">
        <v-icon @click="openProductEditModal" color="blue">mdi-pencil</v-icon>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductListTableLine',
  props: {
    item: {
      type: Object,
      required: true
    },
    categoryId: {
      type: Number,
      default: 0
    },
    showSort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editPrice: false,
      editPosition: false,
      newPrice: null,
      priceLoading: false,
      status: 0,
      statusLoading: false,
      sortLoading: false,
      position: 0
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      updateProduct: 'catalog/product/update',
      updateCategory: 'catalog/category/save'
    }),
    updatePrice () {
      this.priceLoading = true
      this.updateProduct({ token: this.token, product_id: this.item.entity_id, product: { price: this.newPrice } })
        .then(result => {
          this.editPrice = false
          this.$bus.$emit('catalog-product-update', { product_id: this.item.entity_id, product: result.product })
        })
        .finally(() => {
          this.priceLoading = false
        })
    },
    updateStatus () {
      this.statusLoading = true
      this.updateProduct({ token: this.token, product_id: this.item.entity_id, product: { status: this.status } })
        .then(result => {
          this.$bus.$emit('catalog-product-update', { product_id: this.item.entity_id, product: result.product })
        })
        .finally(() => {
          this.statusLoading = false
        })
    },
    updateCategoryPosition () {
      this.sortLoading = true
      let category = {products_positions: {}}
      category.products_positions[this.item.entity_id] = Number(this.position)
      this.updateCategory({ token: this.token, category_id: this.categoryId, category })
        .then(() => this.editPosition = false)
        .finally(() => {
          this.sortLoading = false
        })
    },
    openPriceEdit () {
      this.newPrice = Math.abs(this.item.price).toFixed(2)
      this.editPrice = true
    },
    openProductEditModal () {
      this.$bus.$emit('catalog-product-edit', this.item.entity_id)
    }
  },
  mounted () {
    this.status = this.item.status === 2 ? 0 : this.item.status
    this.position = this.item.position ? this.item.position : 0
  }
}
</script>
<style lang="scss" scoped>
.item-clickable {
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
}

.product-line {
  height: 45px;
  td {
    padding: 0px 16px;
  }
}

.product-line:hover {
  background: #eeeeee;
  cursor: pointer;
}

.column-edit {
  max-width: 135px;
}

.status-switch {
  margin: 0;
  padding: 0;
}
.desktop-button {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}
.mobile-button {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
.product-name {
  @media (max-width: 600px) {
    width: 30%;
  }
}
.status-product {
  @media (max-width: 600px) {
    width: 17%;
    padding-left: 5px;
  }
}
.left {
  text-align: left;
}
.left-filed {
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}
</style>
<style lang="scss">
.status-product {
  .status-switch {
    .v-input__slot {
      justify-content: left;
    }
  }
}
</style>
