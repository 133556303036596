<template>
  <products-list />
</template>
<script>
import ProductsList from '@/components/Catalog/Products/ProductsList'

export default {
  name: 'CatalogProducts',
  components: {
    ProductsList
  }
}
</script>
